import { MenuItem } from "../MenuItem";

export class User {
    Email:string;
    Id_user:number;
    Name:string;
    Password:string;
    PersonnelId:string;
    RelatedCustomer:string;
    RelatedSupplier:string;
    Username:string;
}


export class ContactData {
    Mob             : any;
    Phone           : string;
    Email           : string;
    Country         : any;
    City            : any;
    State           : any;
    Address1        : string;
    Address2        : any;
    Zipcode         : any;
    Note            : any;

}
export class Warehouses {
    Id              : number;
    Permission      : string;
    Name            : string;
}

export class CashAccounts{
    Id              : number;
    Permission      : string;
    Name            : string;
}

export class ConfigOLD {
    AccesoC         : boolean;
    AccesoP         : boolean;
    AccesoPed       : boolean;
    Auto_GenNE      : boolean;
    Auto_GenNI      : boolean;
    CrClieOf        : boolean;
    EntAuto         : boolean;
    Inventario      : boolean;
    IsRspl          : boolean;
    IvaChange       : boolean;
    ModiFecha       : boolean;
    NewProd         : boolean;
    PrintRide       : boolean;
    RucSri          : boolean;
    SriSeries       : boolean;
    SupClient       : boolean;
    Super           : boolean;
    UsPrecio0       : boolean;
    UtilFact        : boolean;
}

export class Config {
    Detail  : string;
    Name    : string;
    Section : string;
    Value   : boolean;
}

export class Users{
    UserId              : number;
    UserCode            : any;
    FullName            : string;
    Password            : any;
    WebUser             : string;
    WebPassword         : string;
    JobPosition         : any;
    Profile             : any;
    Role                : any;
    Salesman            : number;
    RelatedSupplier     : string;
    RelatedCustomer     : string;
    PersonnelId         : string;
    Center              : string;
    ContactData         : ContactData;
    DefaultCashAccount  : number;
    Warehouses          : Array<Warehouses>;
    CashAccounts        : Array<CashAccounts>;
    Config              : Array<Config>;
    Series              : any;
    Centers             : any;
    InvoiceFormat       : string;
    SalesFormat         : string;
    CheckSerieAsig      : number;
    SerieAsig           : string;
}
export class MenuItem2{
    Id              : number;
    Name            : string;
    Icon            : string;
    Controller      : string;
    ParentId?       : number;
    Area            : string;
    Code            : string;
    Action          : string;
    Favorit         : boolean;
    IsControlEditor : boolean;
    TypeProgram     : number;
    Parameters      : string;
    Default         : string;
    Image           : string;
}

export class UsaEmpMenu {
    userId:number;
    id_emp: number;
    menu : Array<MenuItem>;
}

