export class MenuItem {
    Id              : number;
    Name            : string;
    Icon            : string;    
    Controller      : string;
    ParentId?       : number;
    Area?           : string;
    Code?           : string;
    Action?         : string;
    Favorit?        : boolean;
    IsControlEditor?: boolean;
    TypeProgram?    : number;
    Parameters?     : string;
    Default?        : string;
    Image?          : string;
}