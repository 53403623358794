import { environment } from '../environments/environment';

export const APP_SETTINGS = {
  endpoints: {
    api_url:  'https://test.factura-ec.com',//'http://15.235.32.208:443',//'https://orion.eclipsoft.com',//'http://192.168.88.138:8001',//'http://142.44.196.209:443',// 'http://216.172.99.173:8500', //'http://142.44.196.209:443', //'http://orionfe.com:8000',  //''http://142.44.196.209:8000', //'http://ORIONFE.COM:8800',  //'http://192.168.1.7:8000' 'http://FE-NET.NET:8000', //
    printService_url: 'http://localhost:5000/PrintPOS',
    printCodebarService_url: 'http://localhost:5000/PrintBarcode',
    version: '1.01.036',
    api_local: `${document.getElementsByTagName('base')[0].href}`,
    image_url: `${document.getElementsByTagName('base')[0].href}`,
    thumbnail_extension: '',
    WhatsApp: '593999999999',
    routehome:'app/reports/invoices/',
    image_service: environment.production ? `${document.getElementsByTagName('base')[0].href}/server.php` : 'http://localhost:30011/?XDEBUG_SESSION_START=3F24F722',
    //image_upload_service: 'http://www.motoexito.fe-ec.com/uploader/img-uploader.php',
    //image_get_service: environment.production ? `${document.getElementsByTagName('base')[0].href}/uploader/img-uploader.php` :  `http://polaca.fe-ec.com/uploader/img-uploader.php`,
    //image_get_service: 'http://142.44.196.209:81/uploader/img-reader.php',  //`${document.getElementsByTagName('base')[0].href}/img-reader.php`,
    //image_upload_service: environment.production ?  `http://polaca.fe-ec.com/uploader/img-uploader.php`,
    //image_get_service: `http://polaca.fe-ec.com/uploader/img-reader.php`,

    image_upload_service: environment.production ? `${document.getElementsByTagName('base')[0].href}/uploader/img-uploader.php` :  `http://polaca.fe-ec.com/uploader/img-uploader.php`,
    image_get_service: environment.production ? `${document.getElementsByTagName('base')[0].href}/uploader/img-reader.php` :  `http://polaca.fe-ec.com/uploader/img-reader.php`,

    //image_upload_servicea: 'http://216.172.99.173:81/uploader/img-uploader.php',
    //image_get_service: 'http://216.172.99.173:81/uploader/img-reader.php',

    enableLocalService: false,
    enableSSLLocal: false,
    allRolesShowTiles: true,
    /*------------Servidores usados----------
    http://142.44.196.209:8010  Pruebas OK
    http://142.44.196.209:443/ cantos Pruebas
    http://FE-NEt.NET:8000'
    'http://15.235.32.208:8010'
    'http://10.0.0.8:8000', //
    //http://26.49.43.225:8000
      'http://FE-NET.NET:8700',
      'http://181.198.253.105:8600',
      'http://181.198.253.105:8000',
      'http://181.199.95.146:8500',
      'http://186.3.107.114:8001',
      'http://186.3.173.133:8002',
      'http://190.214.18.46:8500',
      'http://192.168.88.133:8000',
      'http://192.168.88.133:8700',
      'http://142.44.196.209:443',  //Cliente real
      'http://192.168.88.133:8000'
      'http://15.235.32.208:8000/',

      'http://142.44.196.209:8000', Pruebas ok

      'http://181.199.95.146:8502/ MEDINATURAL

      'http://142.44.196.209:8000' prueba tracking
      'http://FE-NET.NET:8000' Produccion Stylexsa ok

      'http://orionfe.com:8000'
      'http://192.168.88.151:8000

      'http://15.235.32.208:443'

       http://fe-net.net:8001  pruebas de paty
    */
  },

  formats: {
    currencyCode: 'USD',
    digitsInfo: '1.2-2',
    dateFormat: 'yyyy-MM-dd',
    dateFormatOnlyDigits: 'yyyyMMdd'
  },
  features: {
    enableLandingPage: false,
    disableLogin: false,
    enableRRHHModule: true,
    enableSalesModule: true,
    enablePurchaseModule: true,
    enableCommerceModule: true,
    enablePayment: false,
    visibleColsSearch: true,
    onlyIntCant:true,
    moreInfoShoppingCart:true,
    InterestFreePeriod: 3,
    calcPayment:1
  },
  defaultCredentials: {
    defaultCustomer: 'default',
    defaultPassword: '147852369'
  },
  setups: {
    backgroundImage: 'assets/images/fondoLogin.jpg',
    defaultCountry:'593',
    inventory: {
      checkStock: false,
      ShowStock: false,
      customerShowStock: false,
      showProductCode: true,
      showPrice: true,
      showPrice2: false,
      allowCreate: false,
      updateImageOnly: true,
      enableModelFilter: true,
      enableCategoryFilter: true,
      enableSubcategoryFilter: true,
      enableBrandFilter: true,
      enableReservedStock: true,
      enableTopPagination: false
    },
    cart: {
      order: 1,
      showDetail: 0,
      oneProductByLine: 1,
      priceName: 'Precio de Venta',
      price2Name: 'Precio 2',
      enableInvoiceZero: true,//false,
      enablePaymentCheck: true,//false,
      enablePaymentCredit: true,
      enablePaymentCash: true,
      enablePaymentRetention: true,//false,
      enablePaymentCreditcard: false,//false,
      enablePromoDetail: true,
      enableTransport: false,
    },
    landingPage: {
      components: [
        {
          'name': 'cms-navbar',
          'properties': [
            {
              'items': [
                {
                  'title': 'Home',
                  'route': '/'
                },
                {
                  'title': 'About',
                  'route': '/about'
                },
                {
                  'title': 'Contacts',
                  'route': '/contacts'
                },
                {
                  'title': 'Shop',
                  'route': '/store'
                }
              ],
            },
            {
              'logo': ''
            },
            {
              'brand-name': 'Stylexsa'
            }
          ]
        },
        {
          'name': 'cms-banner',
          'properties': [
            {
              'placeholder': 'Stylexsa'
            },
            {
              'legend': 'La transformación digital de tu negocio en las mejores manos'
            },
            {
              'placeholder-spacing': '5'
            },
            {
              'scroll-to': 'footer'
            },
            {
              'height': '850px'
            },
            {
              'items': [
                'https://s1.1zoom.me/b5050/659/Amaya_Dress_Legs_Lying_down_Stilettos_Beautiful_585702_1366x768.jpg',
                'https://images.wallpaperscraft.com/image/sneakers_shoes_brown_143668_1366x768.jpg',
                'https://images.wallpaperscraft.com/image/sneakers_shoes_legs_115414_1366x768.jpg',
                'https://c.wallhere.com/photos/73/f2/1366x768_px_model_street_Walking_women-586198.jpg!d',
              ]
              // 'items': [
              //   'https://tiaecuador.vteximg.com.br/arquivos/ids/184994/banner%20loquisimos%20octubre.jpg?v=637699371808900000',
              //   'https://tiaecuador.vteximg.com.br/arquivos/ids/184289/banner%20Amstel%20octubre.jpg?v=637687054515830000',
              //   'https://tiaecuador.vteximg.com.br/arquivos/ids/184106/banner%20pickup.jpg?v=637666330502100000',
              //   'https://tiaecuador.vteximg.com.br/arquivos/ids/184108/banner%20whatsapp.jpg?v=637666335868600000',
              //   'https://tiaecuador.vteximg.com.br/arquivos/ids/184289/banner%20Amstel%20octubre.jpg?v=637687054515830000'
              // ]
            }
          ]
        },
        {
          'name': 'cms-catalog',
          'properties': [
            {
              'header': 'Nuestros Productos'
            },
            {
              'btn-text': 'Comprar'
            },
            {
              'catalog': [
                {
                  'name': 'skirts',
                  'image': 'https://s1.1zoom.me/b5050/659/Amaya_Dress_Legs_Lying_down_Stilettos_Beautiful_585702_1366x768.jpg',
                  'url': '/store/12345'
                },
                {
                  'name': 't-shirts',
                  'image': 'https://images.wallpaperscraft.com/image/sneakers_shoes_brown_143668_1366x768.jpg',
                  'url': '/store/12344'
                },
                {
                  'name': 'shirts',
                  'image': 'https://images.wallpaperscraft.com/image/sneakers_shoes_legs_115414_1366x768.jpg',
                  'url': '/store/12343'
                },
                {
                  'name': 'pants',
                  'image': 'https://c.wallhere.com/photos/73/f2/1366x768_px_model_street_Walking_women-586198.jpg!d',
                  'url': '/store/12343'
                },
                {
                  'name': 'skirts',
                  'image': 'https://s1.1zoom.me/b5050/659/Amaya_Dress_Legs_Lying_down_Stilettos_Beautiful_585702_1366x768.jpg',
                  'url': '/store/12345'
                },
                {
                  'name': 't-shirts',
                  'image': 'https://images.wallpaperscraft.com/image/sneakers_shoes_brown_143668_1366x768.jpg',
                  'url': '/store/12344'
                }
              ]
            }
          ]
        },
        {
          'name': 'cms-newsletter',
          'properties': [
            {
              'header': 'Suscribete',
            },
            {
              'legend': 'Enterate de todas nuestras ofertas. iit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.',
            },
            {
              'placeholder': 'Tu email'
            }
          ]
        },
        {
          'name': 'cms-footer',
          'properties': [
            {
              'id': 'footer'
            },
            {
              'placeholder': 'Stylexsa'
            },
            {
              'address': 'Av José de la cuadra ciudadela los Almendros'
            },
            {
              'phones': '(593)991492404'
            },
            {
              'networks': [
                {
                  'name': 'facebook',
                  'url': 'https://www.facebook.com/El-Gaucho-Uruguayo-409961912528771'
                },
                {
                  'name': 'twitter',
                  'url': ''
                },
                {
                  'name': 'instagram',
                  'url': ''
                },
                {
                  'name': 'youtube',
                  'url': ''
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
